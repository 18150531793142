.mc-button-container {
  display: block;
  width: 100%;
  text-align: center;

  button {
    display: block;
    width: 100%;
    border: 0px;
    height: 45px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    cursor: pointer;
    border-radius: 4px;

    .inline-loader {
      width: 30px;
      height: auto;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__primary {
    button {
      background-color: $primaryColorDarkThree;
      color: #fff;
      display: flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;

      &:hover {
        background-color: $secondaryColor;
      }

      &.mc-button-container__status--inactive {
        background-color: #E5E5E5;
        color: #333333;
        display: flex;
        // width: 194px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        cursor: no-drop;
        // opacity: 0.7;
        color: var(--Semanticos-Contenidos-textos, #333);
        font-family: "Gelion Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  &__secondary {
    button {
      background-color: #fff;
      color: #022047;
      border: 2px solid #022047;
      display: flex;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;

      &:hover {
        border: 2px solid $secondaryColor;
        color: $secondaryColor;
        -webkit-box-shadow: 2px 0px 17px 8px rgba(0, 86, 203, 0.49);
        -moz-box-shadow: 2px 0px 17px 8px rgba(0, 86, 203, 0.49);
        box-shadow: 2px 0px 17px 8px rgba(0, 86, 203, 0.49);
      }

      &.mc-button-container__status--inactive {
        background-color: $secondaryColorDisable;
        cursor: no-drop;
        opacity: 0.7;
      }
    }
  }
}