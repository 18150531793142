.mc-page.progress {

  .mc-progress__info {
    &--card {
      background-color: $primaryColor;
      color: #F3F6FE;
      border-radius: 24px;
      box-sizing: border-box;
      padding: 24px;
      text-align: center;
      box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.2);
      -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.2);
      margin-bottom: 25px;

      h2 {
        margin-top: 0px;
        margin-bottom: 0px;
      }
      p {
        margin-top: 5px;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
      .dateTexts {
        font-size: 16px;
        line-height: 20px;
        display: block;
        margin-top: 12px;
        margin-bottom: 12px;
      }

      &__label {
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 44px;
        font-family: "Gelion Bold";

        span {
          display: block;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 34px;
          font-family: "Gelion Bold";
        }
      }

      .mc-button-container {
        width: 167px;
        margin: 0 auto;

        .mc-button-container__status--inactive {
          background-color: #E5E5E5;
          color: #333333;
          opacity: 1 !important;

          .mc-icon {
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
            position: relative;
            top: 2px;
          }
        }
      }

      &.inactive {
        background-color: #EFEEEE66;
        color: #333333;
        box-shadow: 0px 10px 30px 0px rgba(0,0,0,0);
        -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0);
        -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0);

        p {
          margin-top: 0px;
          margin-bottom: 6px;
        }

        .mc-progress__info--card__label {
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .mc-progress__big_award {
    .informationTextTitle {
      padding-bottom: 0px;
    }
    .informationTextSubTitle {
      padding-top: 4px;
    }
    .informationTextPar {
      padding-bottom: 0px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .mc-banner-container {
    margin-bottom: 30px;
  }
}