.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modal {
    background: white;
    padding: 20px;
    border-radius: 24px;
    max-width: 358px;
    width: auto;
    text-align: center;
    position: relative;
    max-height: 444px;
    height: auto;
    padding: 32px 31px;

    h2 {
      color: #0056CB;
      text-align: center;
      font-family: 'Gelion Bold';
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px;
      margin: 0;
      padding: 32px 31px;
    }

    p {
      color: #333;
      text-align: center;
      font-family: 'Gelion Regular';
      font-size: 16px;
      font-style: normal;
      font-weight: bold;
      line-height: 20px;
      margin: 0;
      padding-bottom: 40px;
    }

    small {
      color: #333;
      text-align: center;
      font-family: 'Gelion Regular';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
    }

    .modal-image {
      border-radius: 50%;
      margin-bottom: 0px;
      border: 2px solid black;
      padding: 16px;
      stroke-width: 2px;
      box-shadow: 0px 25px 40px 0px rgba(0, 60, 103, 0.4);
    }
  }

}