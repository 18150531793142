.mc-custom-modal {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;

  &__overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    background-color: rgba(83, 83, 83, 0.8);
  }

  &__center {
    width: 430px;
    height: 100%;
    margin: 0 auto;
    max-width: 90%;
    z-index: 1;

    &--window {
      position: relative;
      background: #FFF;
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
      border-radius: 16px;
      box-sizing: border-box;
      padding: 16px;
      box-shadow: 0px 25px 40px 0px rgba(0, 60, 103, 0.40);
      // box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
      // -webkit-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);
      // -moz-box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.1);

      &__image {
        height: 90px;
        width: 90px;
        margin: 0 auto;
        display: block;
        margin-top: 20px;
        box-sizing: border-box;
        background-position: center center !important;
        background-repeat: no-repeat;
        // background-size: 80% !important;
        border-radius: 50%;
        padding-bottom: 10px;
        border: 2px solid #333333;
        -webkit-box-shadow: -1px 13px 19px -9px rgba(0, 0, 0, 0.7);
        -moz-box-shadow: -1px 13px 19px -9px rgba(0, 0, 0, 0.7);
        box-shadow: -1px 13px 19px -9px rgba(0, 0, 0, 0.7);
      }

      &__title {
        color: #022047;
        text-align: center;
        font-family: "Gelion Bold";
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 44px;
      }

      &__desc {
        color: #333;
        text-align: center;
        font-family: "Gelion Bold";
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        padding-top: 20px;
      }

      &__actions {
        margin: 20px 0px;

        @media only screen and (min-width: 992px) {
          margin-top: 30px;
        }

        .mc-button-container {
          margin: 10px 0px;
        }
      }

      &__terms {
        text-align: center;
        margin-bottom: 16px;

        a {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          text-decoration-line: underline;
          color: $colorText;
        }
      }
    }
  }

  &__close {
    position: absolute;
    right: 22px;
    top: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  &__opened {
    visibility: visible;
    opacity: 1;
    transform: scale(1);

    .mc-custom-modal__center--window {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }

  &.isNotFound {
    .mc-custom-modal__center--window {
      padding-left: 30px;
      padding-right: 30px;
    }
    .not-found-image {
      margin: 0 auto;
      display: block;
      margin-top: 28px;
      margin-bottom: 4px;
    }
    .mc-custom-modal__center--window__desc {
      padding: 0px;
      overflow: auto;
      margin-top: 16px;
      div {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        margin-top: 20px;
        padding: 0 40px;
      }
    }
  }
}