.mc-progressBar-container {
  position: relative;
  height: 4px;
  background: transparent;
  margin: 45px 0;
  border-radius: 4px;
  background-color: #DFE5F9;

  @media only screen and (min-width: 992px) {
    margin-right: 10px;
    margin-left: 10px;
  }

  .mc-progressBar-progress {
    position: absolute;
    left: 0px;
    top: -6px;
    bottom: -6px;
    width: 0;
    background-color: #41D49A;
    margin: auto;
    z-index: 1;
    border-radius: 100px;

    span {
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $secondaryColor;
      border-radius: 4px;
      right: -4px;
      top: -2px;
      z-index: 1;
    }
  }

  .mc-progressBar-percent {
    z-index: 2;
    position: absolute;
    left: 0px;
    right: 0px;
    text-align: center;
    color: $colorText;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 22px;
    top: 6px;

    &.white {
      color: #fff;
    }
  }

  .mc-progressBar-bubble {
    position: absolute;
    width: 50px;
    height: auto;
    bottom: -36px;
    z-index: 2;

    img {
      width: 100%;
      height: auto;
      position: relative;
      left: -19px;
    }
  }

  .mc-progressBar-title {
    position: absolute;
    left: 0px;
    top: -26px;
    font-size: 14px;
    font-weight: 700;
    opacity: 0.5;
  }
  .mc-progressBar-details {
    position: absolute;
    right: 0px;
    left: 0px;
    margin: auto;
    top: 16px;
    width: 50px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
  }

  .mc-progressBar-label {
    position: absolute;
    right: 0px;
    top: -25px;
    font-size: 13px;
  }

  &.has-title {
    margin-top: 25px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 10px;
    }
  }

  &.col-2 {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }
}