@font-face {
  font-family: 'Gelion Regular Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gelion Regular Italic'), url('../../public/assets/fonts/Gelion Regular Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gelion Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Gelion Regular'), url('../../public/assets/fonts/Gelion Regular.woff') format('woff');
}

@font-face {
  font-family: 'Gelion Light';
  font-style: normal;
  font-weight: normal;
  src: local('Gelion Light'), url('../../public/assets/fonts/Gelion Light.woff') format('woff');
}

@font-face {
  font-family: 'Gelion Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gelion Thin Italic'), url('../../public/assets/fonts/Gelion Thin Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gelion Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gelion Light Italic'), url('../../public/assets/fonts/Gelion Light Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gelion Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Gelion Thin'), url('../../public/assets/fonts/Gelion Thin.woff') format('woff');
}

@font-face {
  font-family: 'Gelion Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gelion Medium Italic'), url('../../public/assets/fonts/Gelion Medium Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gelion Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Gelion Medium'), url('../../public/assets/fonts/Gelion Medium.woff') format('woff');
}

@font-face {
  font-family: 'Gelion Semi Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gelion Semi Bold Italic'), url('../../public/assets/fonts/Gelion SemiBold Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gelion Semi Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Gelion Semi Bold'), url('../../public/assets/fonts/Gelion SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Gelion Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gelion Bold Italic'), url('../../public/assets/fonts/Gelion Bold Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gelion Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Gelion Bold'), url('../../public/assets/fonts/Gelion Bold.woff') format('woff');
}

@font-face {
  font-family: 'Gelion Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Gelion Black Italic'), url('../../public/assets/fonts/Gelion Black Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gelion Black';
  font-style: normal;
  font-weight: normal;
  src: local('Gelion Black'), url('../../public/assets/fonts/Gelion Black.woff') format('woff');
}


// Base
@import "./base/variables";
@import "./base/normalize";

// Components
@import "./components/button";
@import "./components/awards";
@import "./components/allies";
@import "./components/profile";
@import "./components/modals";
@import "./components/loginForm";
@import "./components/accordeon";
@import "./components/progressBar";
@import "./components/progressCircle";
@import "./components/sliderRotator";
@import "./components/cardBox";
@import "./components/textImageBox";
@import "./components/banner";
@import "./components/alertMessage";
@import "./components/dashboard";
@import "./components/preloader";
@import "./components/goalCounter";
@import "./components/counterDown";
@import "./components/modal";

// Layout
@import "./layout/container";
@import "./layout/columns";
@import "./layout/header";
@import "./layout/footer";
@import "./layout/general.scss";

// Pages
@import "./pages/general";
@import "./pages/progress";

// general
.mc-app-fullheight {
  height: 100%;
}

.mc-app-rely {
  position: relative;
  display: table;
  height: 100%;
  width: 100%;
  z-index: 2;

  .mc-app-centry {
    position: relative;
    vertical-align: middle;
    display: table-cell;
    z-index: 1;
  }
}

.slow_ani {
  -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
  -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
  -o-transition: all .3s cubic-bezier(.77,0,.175,1);
  transition: all .3s cubic-bezier(.77,0,.175,1);
}


.App {
  &.modalOpens {
    height: 750px;
    .mc-awards-container__steper {
      .slick-slider {
        .slick-track,
        .slick-list {
          position: fixed !important;
          left: 0px !important;
          top: 0px !important;
          right: 0px !important;
          bottom: 0px !important;
          width: auto !important;
          height: auto !important;
          z-index: 999;

          .mc-awards-container__items {
            .mc-awards-container__items--item {
              background-color: transparent !important;

              .mc-custom-modal__overlay {
                background-color: rgba(83, 83, 83, 1);
              }
              .mc-awards-container__items--item__image,
              .mc-awards-container__items--item__info {
                visibility: hidden !important;
              }
            }
          }
        }
      }
    }
    .mc-header-container {
      opacity: 0;
    }
    .mc-footer-container {
      display: none !important;
    }
  }

  &.noLoggedIn {
    .mc-header-container-type-1 {
      .mc-header-container__nav {
        @media only screen and (min-width: 992px) {
          right: 10px;
        }
      }
    }
    .mc-header-container-page-login {
      .mc-header-container__mobile--submenu {
        display: none;
        
      }
    }
  }

  .mc-section {
    display: block;
    margin: 40px 0px;
  }

}

// Pages
.demo-section {
  margin: 20px 0px;
  display: block;
}