.mc-page {
  position: relative;
  padding: 0 0 70px;

  &.dashboard {
    background-color: rgb(240, 240, 240);
    padding-top: 25px;

    .mc-app-container {
      width: 1110px;
      padding: 0 15px;
    }
    .mc-progressBar-bubble {
      img {
        transform: scale(0.7);
        position: relative;
        top: -4px;
        left: -10px;
      }
    }
    .mc-progressBar-container {
      margin-bottom: 80px;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        z-index: 1;
        height: 1px;
        background-color: rgb(198, 198, 198);
        position: absolute;
        bottom: -35px;
        left: 0px;
      }
    }
  }

  &.terms,
  &.faqs {
    h1 {
      @media only screen and (max-width: 992px) {
        text-align: center;
      }
    }
  }

  .mc-static-content {
    margin: 30px 0;

    @media only screen and (min-width: 992px) {
      margin: 50px 0;
    }
  
  }

  &.mechanic {
    @media only screen and (min-width: 992px) {
      padding-bottom: 30px;
      & > .mc-banner-container {
        position: absolute;
        left: 0px;
        width: 100%;
        height: auto;
        top: 0px;
      }
    }
    .mc-app-container {
      @media only screen and (min-width: 992px) {
        max-width: 90%;
      }
    }
    & > .mc-app-container {
      @media only screen and (min-width: 992px) {
        br {
          display: none;
        }
      }
    }
    .mc-card-container {
      @media only screen and (min-width: 992px) {
        display: inline-block;
        vertical-align: top;
        width: 30.5%;
        margin: 0 1.5%;
        margin-top: 30px;

        &:first-of-type {
          margin-left: 0px;
        }
        &:last-of-type {
          margin-right: 0px;
        }

        .mc-card-text-title {
          h2 {
            padding-bottom: 10px;
          }
        }

        .mc-card-text {
          box-sizing: border-box;
          padding: 0 15px;
          height: 134px;

          .mc-card-text-par {
            div {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &.progress {
    @media only screen and (min-width: 992px) {
      & > .mc-banner-container {
        position: absolute !important;
        left: 0px !important;
        width: 100% !important;
        height: auto !important;
        top: 0px !important;
      }
    }

    .mc-progress__info {
      @media only screen and (min-width: 992px) {
        margin-top: 32px;
        text-align: center;
        margin-bottom: 52px;

        .mc-progress__info--card {
          width: 358px;
          display: inline-block;
          vertical-align: middle;
          margin: 0 18px;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
    .mc-progress__big_award {
      @media only screen and (min-width: 992px) {
        width: 360px;
        text-align: left;
        display: inline-block;
        vertical-align: middle;

        .informationTextTitle,
        .informationTextSubTitle,
        .informationTextParSmall {
          text-align: left;
        }
        .mc-goal-counter {
          margin-left: 0px;
          margin-top: 0px;
        }
        .mc-button-container {
          width: 220px;
        }
      }
    }
    .mc-banner-container {
      @media only screen and (min-width: 992px) {
        width: 431px;
        display: inline-block;
        vertical-align: middle;
        // margin-left: 30px;
        top: 38px;
      }
    }
  }

  &.awards {
    @media only screen and (min-width: 992px) {
      .containerAward {
        background: transparent !important;
        .mc-app-container {
          max-width: 90%;
        }
      }
    }
  }

  .desktop-hero {
    @media only screen and (min-width: 992px) {
      position: relative;

      .mc-app-container {
        max-width: 90%;
      }

      .desktop-hero-labels {
        position: absolute;
        z-index: 1;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 100%;
        text-align: left;

        .informationTextTitle {
          text-align: left;
        }
        .informationTextPar {
          text-align: left;
        }
      }

      & > img {
        width: 100%;
      }
    }
  }
}