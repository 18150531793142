.mc-login {
  position: relative;
  height: auto;
  // background-color: rgb(235, 235, 235);
  padding: 30px 0px 0px 0px;
  min-height: 400px;

  &__form {
    position: relative;
    display: block;
    margin: 0 auto;
    background-color: #fff;
    padding: 26px 20px;
    padding-bottom: 28px;
    box-sizing: border-box;
    border-radius: 16px;
    -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    z-index: 10;


    &--fields {
      position: relative;
      margin-bottom: 22px;
    }

    &--overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 5;
      background-color: #fff;
      opacity: 0.5;
      cursor: no-drop;
    }

    .title-form {
      margin: 0;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-align: left;
      padding-bottom: 18px;
    }
  }
}

@media only screen and (min-width: 992px) {
  .mc-login {
    position: relative;
    top: 30px;
  }
}

.mc-input-container {

  &__box {
    position: relative;
    margin-bottom: 22px;

    label {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      display: block;
      margin-bottom: 8px;
      color: #022047;
    }

    input,
    textarea {
      width: 100%;
      box-sizing: border-box;
      background-color: #fff;
      border: 0px;
      outline: 0px;
      height: 50px;
      position: relative;
      border-radius: 6px;
      font-size: 16px;
      padding: 0 12px;
      min-width: 100%;
      max-width: 100%;
      border: 1px solid #C2C2C2;
      color: #919191;
      

      &[type="date"] {
        text-transform: uppercase;
        background-image: url('../../../public/assets/images/calendar.png');
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) center;
      }
    }

    textarea {
      padding-top: 5px;
      padding-bottom: 5px;
      resize: none;
      height: 130px;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      background: none;
      background-size: 0px;
    }

    input::placeholder,
    input:-ms-input-placeholder,
    input::-ms-input-placeholder {
      opacity: 1 !important;
      font-family: 'Gelion Regular';
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;

    }

    &.terms,
    &.personalData {
      display: block;
      position: relative;
      padding-left: 30px;
      margin-bottom: 15px !important;
      cursor: pointer;
      font-size: 16px !important;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-weight: normal !important;
      line-height: 8px;

      a {
        text-decoration: none;
        margin-left: 4px;
        color: $colorText;
        cursor: initial !important;
        font-size: 13px;
      }

      input {
        position: absolute;
        cursor: pointer;
        height: 100%;
        width: 100%;
        left: 0px;
        top: 0px;
        z-index: 2;
        opacity: 0;
      }

      label {
        display: inline;
        font-size: 13px;
        color: $colorText;
      }

      .mc-input-checkbox__checkmark {
        position: absolute;
        top: 0px;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: transparent;
        border-radius: 6px;
        border: 2px solid #919191;
      }

      .mc-input-error {
        margin-top: 5px;
        line-height: 16px !important;
      }
    }

    &.terms:hover input~.mc-input-checkbox__checkmark,
    &.personalData:hover input~.mc-input-checkbox__checkmark {
      background-color: rgba(255, 255, 255, 0.3);
    }

    &.terms input:checked~.mc-input-checkbox__checkmark,
    &.personalData input:checked~.mc-input-checkbox__checkmark {
      background-color: $primaryColor;
      border: 2px solid $primaryColor !important;
    }

    .mc-input-checkbox__checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    &.terms input:checked~.mc-input-checkbox__checkmark:after,
    &.personalData input:checked~.mc-input-checkbox__checkmark:after {
      display: block;
    }

    &.terms .mc-input-checkbox__checkmark:after,
    &.personalData .mc-input-checkbox__checkmark:after {
      left: 6px;
      top: 2px;
      width: 4px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg) scale(0.8);
      -ms-transform: rotate(45deg) scale(0.8);
      transform: rotate(45deg) scale(0.8);
    }

    .mc-input-by-character {
      input {
        display: inline-block;
        vertical-align: middle;
        width: 20%;
        min-width: 20px;
        margin: 0 1%;

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

.mc-input-separated-container {
  input {
    min-width: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 0 1%;
    color: #000;
  }
}

.mc-input-container__box--error {
  input {
    border: 1px solid red !important;
  }
}

.mc-input-error {
  color: red;
  font-size: 12px;
  display: block;
  // padding: 8px 8px;
  border-radius: 4px;
}