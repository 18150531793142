div#root {
    padding-top: 60px;
}

.mc-header-container__menu {
    position: fixed;
    width: 100%;
    top: 0px;
}

.content-only-mobile {
    display: none !important;
}

@media only screen and (max-width: 992px) {
    .content-only-desktop {
        display: none !important;
    }
    .content-only-mobile {
        display: block !important;
    }
}

.loginContainer {
    background-image: url("../../../public/assets/images/bgMobile.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;

    .textContainer {
        padding-top: 30px;

        .imgContainer {
            text-align: center;
        }

        .parrafoContainer {
            width: 358px;
            margin: 0 auto;

            p {
                font-size: 'Gelion Semi Bold';
                font-size: 16px;
                font-weight: 700;
                line-height: 20px;
                text-align: center;
            }
        }

    }

    @media only screen and (min-width: 992px) {
        background-image: url("../../../public/assets/images/img_backgound_v3.png");
        background-size: cover;

    }
}

@media only screen and (min-width: 992px) {
    .loginContainer .textContainer {
        padding-top: 0px;
        position: relative;
        right: calc(50% - 20%);
        top: 60px;
    }

    .parDescDesktop {
        text-align: center;
        margin-top: 20px;
    }

    .cardsContainer {
        display: grid;
        grid-template-columns: repeat(2, 33% 66%);
    }

    ul.slick-dots {
        position: relative;
        top: 50px;
        right: 75%;
    }
}

.arrow-down-container-desktop {
    position: relative;
    top: -80px;
    display: flex;
    justify-content: center;
    width: 100px;
    right: -50%;
}

.arrow-down {
    transition: transform 0.5s ease;
    /* Duración y tipo de transición */
}

.arrow-down-container-desktop .arrow-down.hover {
    transform: translateY(10px);
    /* Mueve la imagen hacia abajo 10px cuando se hace hover */
}



.informationText {
    margin: 0 auto;
    color: #333333;
    padding-left: 50px;
    padding-right: 28px;

    @media only screen and (max-width: 992px) {
        padding-left: 18px;
        padding-right: 18px;
    }

    .informationTextTitle {
        font-family: 'Gelion Bold';
        font-size: 40px;
        font-weight: 700;
        line-height: 44px;
        text-align: center;
        color: #0056CB;
        margin: 0;
        padding-top: 60px;
        padding-bottom: 20px;

        @media only screen and (max-width: 992px) {
            padding-bottom: 10px;
        }
    }

    .informationTextPar {
        font-family: 'Gelion Regular';
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        margin: 0;
        padding-bottom: 30px;

        @media only screen and (max-width: 992px) {
            font-size: 16px;
            line-height: 20px;
        }

        strong {
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            text-align: center;
        }

    }
}

.arrow-down-container {
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 992px) {
        margin-top: 20px;
    }
}


//mc-card-container
.container-card-mechanic {
    margin-top: 0px;
}

.mc-card-container {
    width: 358px;
    margin: 0 auto;
    padding-bottom: 20px;
    background-color: #F3F6FE;
    // margin: 10px;
    border-radius: 24px;
    @media only screen and (min-width: 992px) {
        margin-left: 0px;
    }

    .mc-card-img {
        .img-card {
            width: 100%;
            // height: 211px;
            // height: 220px;
        }
    }

    .mc-card-text {
        background-color: #F3F6FE;
        // height: 193px;
        height: 211px;
        border-radius: 0 0 24px 24px;

        @media only screen and (max-width: 992px) {
            height: auto;
            padding-bottom: 12px;
        }

        h2 {
            //styleName: Title/h5-bold;
            font-family: 'Gelion Black';
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
            text-align: center;
            color: #022047;
            padding: 10px 5px 5px;
            margin: 0;
        }

        .mc-card-text-par {
            div {
                //styleName: Paragraph/p-regular;
                // font-family: Gelion;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                color: #0F0F0F;
                width: 323px;
                margin: 0 auto;
            }
        }
    }
}

.social-media-container {
    background: rgb(3, 133, 197);
    background: linear-gradient(90deg, rgba(3, 133, 197, 1) 0%, rgba(0, 60, 103, 1) 100%);
    width: 351px;
    height: 55px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    border-radius: 8px;
    margin-top: 30px;

    @media only screen and (max-width: 992px) {
        width: 100%;
    }
}

.trasactional-zone-container {
    width: 359px;
    height: 150px;
    background-color: #F3F6FE;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 30px;
    padding-top: 20px;

    @media only screen and (max-width: 992px) {
        width: 100%;
    }

    .banca-container {
        display: flex;
        justify-content: space-evenly;
        height: 40px;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            right: 0px;
            left: 0px;
            width: 2px;
            height: 88px;
            background-color: #313131;
            margin: auto;
        }

        .banca-app {
            display: inline-block;
            width: 50%;
            text-align: center;

            img {
                width: 40px;
                height: 40px;
            }

            p {
                margin: 0;
                align-self: center;
                padding-right: 8px;
            }
        }

        .banca-web {
            display: inline-block;
            width: 50%;
            text-align: center;

            img {
                width: 40px;
                height: 40px;
            }

            p {
                margin: 0;
                align-self: center;
                padding-left: 8px;
            }
        }
    }

    .zona-transaccional {
        text-align: center;
        margin-top: 68px;
    }
}


.footer-six {
    background: rgb(3, 133, 197);
    background: linear-gradient(90deg, rgba(3, 133, 197, 1) 0%, rgba(0, 60, 103, 1) 100%);
    width: 100%;
    height: 336px;
    margin: 0 auto;

    .vigilado-img {
        position: absolute;
        margin-top: 64px;
        margin-left: 35px;

        @media only screen and (max-width: 992px) {
            margin-top: 28px;
        }
    }

    @media only screen and (min-width: 992px) {
        .vigilado-img {
            margin-left: 80px;
        }
    }

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        padding-top: 40px;

        .img-uno {
            width: 224px;
            height: 55px;
            margin-bottom: 20px;
        }


        .img-dos {
            width: 189px;
            height: 33px;
            position: relative;
            top: -15px;
        }

        p {
            color: #ffffff;
        }
    }
}

.mc-footer-container__text {
    color: #313131;
}

.mc-footer-container__section--links a {
    text-decoration-line: underline;
    color: #002449;
}



////////////////DOTS////////////
.slick-dots li {
    width: 16px !important;
    height: 16px !important;
    margin: 0 15px !important;
}

.mc-slider-rotator .slick-slider .slick-dots li.slick-active {
    background-color: #364773 !important;
}



////////////////////////////////prueba
.mc-slider-rotator {
    position: relative;
}

.mc-slider-rotator .slick-prev,
.mc-slider-rotator .slick-next {
    position: absolute;
    top: 106%;
    transform: translateY(-50%);
    z-index: 1;

    @media only screen and (max-width: 992px) {
        top: auto;
        bottom: -62px;
    }
}

.mc-slider-rotator .slick-prev {
    left: calc(50% - 115px);
    margin-top: 5px;
    margin-left: 2px;
}

.mc-slider-rotator .slick-next {
    right: calc(50% - 120px);
    margin-top: 5px;
}

.mc-slider-rotator .slick-dots {
    position: absolute;
    bottom: -35px;
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 992px) {
        bottom: -45px;
    }
}
.login {
    .mc-slider-rotator .slick-dots {
        @media only screen and (min-width: 992px) {
            display: block !important;
            width: auto;
            left: -42%;
            right: auto;
            bottom: auto;
            top: 68%;

            li {
                margin: 0 10px !important;
            }
        }
    }
    .mc-card-container .mc-card-text {
        @media only screen and (min-width: 992px) {
            height: 140px;
        }
    }
}


.mc-slider-rotator .slick-prev::before,
.mc-slider-rotator .slick-next::before {
    color: black;
    display: none;
}

.footer-desktop-text-container {
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    margin: 0 auto;

    .textFooter {
        margin-left: 80px;
        text-align: justify;
    }
}

@media only screen and (min-width: 992px) {
    .footer-six div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 3rem;
        align-items: center;
        margin-top: 30px;
        padding-top: 40px;
    }

    .textParFooter {
        font-family: 'Gelion Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
    }

    .slick-arrow.slick-prev {
        position: absolute;
        left: -44.5%;
        top: 72%;
        bottom: auto;

        &.slick-disabled {

        }
    }

    .slick-arrow.slick-next {
        position: absolute;
        left: -44.5%;
        top: 72%;
        bottom: auto;
        right: auto;
        margin-left: 145px;
    }
}



.informationTextTitle {
    font-family: 'Gelion Bold';
    font-size: 56px;
    font-weight: 700;
    line-height: 58px;
    text-align: center;
    color: #0056CB;
    margin: 0;
    padding-top: 30px;
    padding-bottom: 15px;

    @media only screen and (max-width: 992px) {
        font-size: 42px;
        line-height: 42px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.informationTextSubTitle {
    font-family: 'Gelion Bold';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: #022047;
    margin: 0;
    padding-top: 0px;
    padding-bottom: 15px;
}

.informationTextPar {
    font-family: 'Gelion Regular';
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin: 0;
    padding-bottom: 20px;
    color: #333;

    @media only screen and (max-width: 992px) {
        text-align: center;
        padding: 0 20px;
    }

    &.minimal-width {
        width: 385px;
    }
}

.informationTextParSmall {
    font-family: 'Gelion Regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 20px;
    color: #333;
    max-width: 680px;
}

.mc-banner-container {
    position: relative;
    z-index: 0;
    margin-bottom: 30px;
}

.allianceBG {
    position: absolute;
}

.containerAlliances {
    background-image: url("../../../public/assets/images/Imgbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 250px;
    margin-bottom: -250px;

    &.is-major-award {
        background-image: url("../../../public/assets/images/ImgbgMajor.png");
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: 80px;

        @media only screen and (max-width: 992px) {
            padding-bottom: 10px;
            background: transparent;
            margin-bottom: 10px;
        }
    }

    .mc-app-container {
        padding-left: 0px;

        @media only screen and (max-width: 992px) {
            padding-right: 0px;
        }

        .informationTextTitle,
        .informationTextPar {
            text-align: center;
        }
    }
}

.major-items {
    width: 809px;
    margin: 30px auto;

    @media only screen and (max-width: 992px) {
        width: 100%;
    }
    &__item {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        @media only screen and (max-width: 992px) {
            display: block;
            margin-bottom: 60px;
        }

        .major-items__items--image {
            width: 42%;

            @media only screen and (max-width: 992px) {
                width: 100%;
                margin-bottom: 10px;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
        .major-items__items--labels {
            align-items: center;
            padding-left: 40px;
            width: 58%;

            @media only screen and (max-width: 992px) {
                width: 100%;
                padding-left: 0px;
            }

            h3 {
                font-family: "Gelion Bold";
                font-size: 32px;
                font-weight: 700;
                line-height: 34px;
                text-align: left;
                color: #0056CB;
                margin: 0;

                @media only screen and (max-width: 992px) {
                    font-size: 22px;
                    line-height: 26px;
                }
            }
            p {
                font-size: 16px;
                line-height: 20px;
                @media only screen and (max-width: 992px) {
                    line-height: 24px;
                }
            }
            img {
                width: 100%;
                height: auto;
                margin-top: 20px;
            }
        }
    }
}

.extra-banner {
    position: relative;
    background-color: #F3F6FE;
    border-radius: 20px;
    padding: 34px 22px;
    width: 809px;
    margin: 30px auto;
    margin-top: 60px;
    box-sizing: border-box;
    text-align: center;
    margin-bottom: 0px;
    h3 {
        color: #008ACC;
        font-size: 40px;
        margin-top: 0px;
        line-height: 44px;
        font-weight: 700;
        font-family: "Gelion Bold";
        margin-bottom: 12px;
    }
    p {
        font-family: "Gelion Bold";
        color: #022047;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        margin: 0px;
        a {
            color: #022047;
        }
    }

    @media only screen and (max-width: 992px) {
        width: auto;
        h3 {
            margin-bottom: 18px;
        }
        p {
            font-size: 24px;
            line-height: 28px;
        }
    }
}

.containerAward {
    background-image: url("../../../public/assets/images/img-banner-award-mobile.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
}

.containerRedemption {
    background-image: url("../../../public/assets/images/ImgbgMajorM.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    padding-bottom: 30px;
}

.slick-active {
    .inactive-dot {
        display: flex;
        width: 20px;
        height: 20px;
        flex-direction: column;
        justify-content: center;
        color: #FFF;
        text-align: center;
        font-family: 'Gelion Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        background-color: #002449;
        border-radius: 50%;
    }
}


.mc-container-pop-up {
    display: flex;
    justify-content: space-between;
    width: 410px;
    height: 60px;
    padding: 16px;
    border-radius: 16px;
    align-items: center;
    box-shadow: 0px 8px 24px 0px rgba(0, 60, 103, 0.4);
    margin: 0 auto;
    position: fixed;
    bottom: 100px;
    right: 50px;
    z-index: 100;
    background: white;

    @media only screen and (max-width: 992px) {
        right: 10px;
        left: 10px;
        width: auto;
        bottom: 30px;
    }

    .mc-icon {
        transform: scale(1.2);
    }

    &__message {
        color: #333;
        font-family: "Gelion Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}

.title_redentions_container {
    margin-top: 80px;
}

.container-no-redemptions {
    p {
        strong {
            text-align: center;
            color: #002449;
            font-family: "Gelion Regular";
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }

        margin-top: 20px;
        color: #002449;
        text-align: center;
        font-family: "Gelion Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }

    .btn-progress {
        margin-top: 50px;
        margin-bottom: 30px;
    }
}

.back-button {
    display: flex;
    padding-top: 40px;

    @media only screen and (max-width: 992px) {
        padding-left: 16px;
    }

    span {
        text-decoration: underline;
        color: #364773;
        font-family: "Gelion Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;

        &:hover {
            color: #0056CB;
        }
    }
}

.containerTerm,
.containerFaqs {
    background-image: url("../../../public/assets/images/terms-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 144px;
    // background-size: 100% 144px;
}

.mc-container-not-found {
    background-image: url("../../../public/assets/images/no-found-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;

    @media only screen and (max-width: 992px) {
        background-image: url("../../../public/assets/images/no-found-mobile-2.png");
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.mc-container-not-found__image {
    display: flex;
    justify-content: center;

    img {
        padding-top: 20px;
        padding-bottom: 40px;

        @media only screen and (max-width: 992px) {
            width: 100%;
            height: auto;
            padding-top: 30px;
            padding-bottom: 20px;
        }
    }
}

.container-button-notfound {
    padding-bottom: 40px;
}

.informationTextParNotFound {
    padding-bottom: 5px;
}

.page-notfound {
    padding-bottom: 20px;
}

.mc-terms-container {
    &__title {
        color: #0056CB;
        font-family: "Gelion Bold";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        margin: 10px 0px;
    }

    &__parr {
        color: #040303;
        font-family: "Gelion Regular";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }

    &__title-small {
        color: #0056CB;
        font-family: "Gelion Bold";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
    }

    &__parr-small {
        color: #040303;
        font-family: "Gelion Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}

.informationTextTitleModal {
    color: #0056CB;
    text-align: center;
    font-family: "Gelion Bold";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    margin-bottom: -10px;
    margin-top: 0;
}

.informationTextParrModal {
    color: #333;
    text-align: center;
    font-family: "Gelion Regular";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}


@media(min-width: 992px) {

    //Beneficios

    .containerAlliances {
        background-image: url("../../../public/assets/images/beneficios-bg-desk.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 324px;
        display: flex;
        justify-content: left;
        align-items: center;
        padding-bottom: 0px;
        margin-bottom: 0px;

        .mc-app-container {
            padding-left: 120px;

            .informationTextTitle,
            .informationTextPar {
                text-align: left;
            }
        }

        &.is-major-award {
            .mc-app-container {
                padding-left: 0px;
            }
        }
    }

    //Faqs

    .containerFaqs {
        background-image: url("../../../public/assets/images/faqs-bg-desk.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .containerTerm {
        background-image: url("../../../public/assets/images/faqs-bg-desk.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .terms-page {

        .back-button {
            padding: 40px;
            padding-bottom: 0px;
            position: relative;
            margin: 0 auto;
            left: auto;
            right: auto;
            width: 1072px;
        }
    }

    .back-button {
        padding: 40px;
        position: relative;
        left: 13%;
    }

    .mc-faqs-container {
        width: 690px;
        margin: 0 auto;
    }

    // Redemption
    .containerRedemption {
        background-image: url("../../../public/assets/images/redemptions-bg-desk.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        height: 463px;
        display: flex;
        padding-bottom: 0px;

        .mc-app-container {
            height: 318px;
            margin: 0;
            padding-left: 130px;
            padding-top: 72px;
            padding-bottom: 73px;
            text-align: start;

            .container-title-redemption {
                width: 460px;
            }

            .informationTextTitle,
            .informationTextPar {
                text-align: left;
            }

            .informationTextPar {
                text-align: left;
                font-family: "Gelion Regular";
                font-weight: 400;
            }

            .container-no-redemptions p {
                margin-top: 0px;
                text-align: left;
            }

            .container-btn-redentions {
                display: flex;
                align-items: baseline;
                gap: 1rem;
                margin-top: -30px;

                .mc-button-container {
                    width: 140px;
                }
            }
        }
    }

    .container-travel {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5rem;

        .title_redentions_container {

            .informationTextTitle,
            .informationTextSubTitle,
            .informationTextPar {
                text-align: left;
                padding-bottom: 10px;
            }
        }

        .mc-goal-counter {
            width: 100%;

            &.is-simple-version {
                width: 321px;
                margin-left: 0;
            }
        }

        .mc-button-container {
            width: 300px;
        }
    }

    .redemption-trav {
        width: max-content;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        padding-top: 110px;
        // position: sticky;
        // top: 50%;
    }


    //Not FOUND

    .mc-container-not-found {
        background-image: url("../../../public/assets/images/not-found-bg-desk.png");
        height: 744px;

        .mc-app-container {
            display: flex;
        }
    }

    .mc-container-not-found__image {
        display: flex;
        justify-content: center;
        width: 558px;
        height: 280px;
    }

    .mc-container-not-found .mc-app-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 236px;
    }


    //Progress

    .containeer-big-award {
        display: flex;
        justify-content: center;
        gap: 2rem;
    }
}


@media(max-width: 992px) {
    .arrow-prev-awards {
        left: 30px !important;
    }
    .arrow-next-awards {
        right: 30px !important;
        bottom: -3.8% !important;
    }
}

.mc-alliances-container__items--item__desc {
    b {
        font-weight: bold;
        font-size: 17px;
    }
}

.term_awards_pdf {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    padding: 0 25px;
}


.mc-custom-modal__center--window.slow_ani {
    // max-height: 450px;
}

.mc-custom-modal__center--window__desc {
    max-height: 150px;
    padding-top: 5px;
}

.mc-custom-modal__center--window__desc::-webkit-scrollbar {
    width: 12px;
    /* width of the entire scrollbar */
}

.mc-custom-modal__center--window__desc::-webkit-scrollbar-track {
    background: #F3F6FE;
    // background: #364773;
    /* color of the tracking area */
}

.mc-custom-modal__center--window__desc::-webkit-scrollbar-thumb {
    // background-color: #F3F6FE;
    background-color: #364773;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    // border: 3px solid #364773;
    border: 3px solid #F3F6FE;
    /* creates padding around scroll thumb */
}

.tyc-black {
    color: #0056CB;
}

@media(max-width: 992px) {
    .mc-terms-container {
        display: flex;
        flex-direction: column;
        text-align: justify;
        // padding: 0 15px;
    }

    .mc-terms-container {

        p,
        ul {
            font-size: 20px;
        }
    }
}


.mc-terms-container {

    p,
    ul {
        font-size: 17px;
    }
}


.mc-input-container__box.terms a,
.mc-input-container__box.personalData a {
    position: relative;
    z-index: 10;
    padding: 10px 0;
    cursor: pointer !important;
    text-decoration: underline;
}


.mc-page.progress .mc-progress__info--card.green_class {
    background-color: #41D49A;
    color: #022047;

    .mc-progressBar-container .mc-progressBar-progress {
        background-color: #022047;
    }

    .mc-button-container__primary button {
        background-color: #F3F6FE;
        color: #022047;
        border: 1px solid #022047;
    }
}

.mc-page.progress .mc-progress__info--card.noProgress {
    background-color: #ADBAE6 !important;
    color: #022047;

    .mc-progressBar-container .mc-progressBar-progress {
        background-color: #022047;
    }

    .mc-button-container__primary button {
        background-color: #F3F6FE;
        color: #022047;
        border: 1px solid #022047;
    }
}

.mc-page.progress .mc-progress__info--card.noActive {
    background-color: rgba(255,255,255,0.4) !important;
    color: #022047;
    box-shadow: none;

    .mc-progressBar-container {
        display: none !important;
    }

    p {
        margin-bottom: 0px;
    }

    .mc-progress__info--card__label {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 30px;

        span {
            font-size: 22px;
            line-height: 28px;
            display: inline-block;
            margin-left: 6px;
        }
    }

    .mc-progressBar-container .mc-progressBar-progress {
        background-color: #022047;
    }

    .mc-button-container__primary button {
        background-color: #F3F6FE;
        color: #022047;
        border: 1px solid #022047;
    }
}

.banner-major-award {
    margin: 10px auto;
    display: block;
    width: 780px;
    height: auto;
    margin-top: 90px;
    @media(max-width: 992px) {
        width: 100%;
    }
}

@media(max-width: 992px) {
    .mc-header-container.mc-header-container-type-4 {
        z-index: 100;
    }
}

.mc-footer-container.mc-footer-container-type-6 {
    position: relative;
    z-index: 1;
}

.parrFormQuantum{
    text-align: center;
    font-size: 14px;
    margin-top: 10;
}

.mc-page.redemptions {
    .mc-awards-container__items {
        margin-top: 20px;
    }
}

.big-tooltip {
    position: relative;
    background-color: #FFCC55;
    padding: 16px 0px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    z-index: 2;
    border-bottom: 1px solid #ba9133;

    @media only screen and (max-width: 992px) {
        margin-top: 10px;
    }

    button {
        width: 16px;
        height: 16px;
        margin: 0;
        border: 0px;
        background-color: transparent;
        position: absolute;
        right: 21px;
        top: 0;
        bottom: 0;
        cursor: pointer;
        margin: auto;
        svg {
            width: 100%;
            height: autp;
        }
    }
}

.mc-page {
    &.mechanic {
        .mc-app-container {
            @media only screen and (min-width: 992px) {
                width: 1188px;
                max-width: 1188px !important;
            }
        }

        @media only screen and (max-width: 992px) {
            .container-card-mechanic {
                .mc-card-container {
                    width: 100%;
                }
            }
            .informationTextParSmall {
                font-size: 15px;
            }
        }
    }
    &.progress {
        &.is-tooltip {
            @media only screen and (min-width: 992px) {
                .mc-banner-container {
                    top: 56px !important;
                }
            }
        }
    }
    &.awards {
        @media only screen and (min-width: 992px) {
            .slick-dots li {
                margin: 0 10px !important;
                .inactive-dot {
                    font-weight: bold !important;
                }
                &.slick-active {
                    .inactive-dot {
                        font-weight: 400 !important;
                    }
                }
            }
            .arrow-prev-awards {
                left: 42% !important;
            }
            .arrow-next-awards {
                right: 43% !important;
                bottom: -4.5% !important;
            }
        }
        @media only screen and (max-width: 992px) {
            padding-bottom: 10px;
            .informationTextPar {
                padding-bottom: 40px;
            }
            .arrow-next-award {
                display: block !important;
            }
        }
    }
    &.alliancesMajor {
        @media only screen and (max-width: 992px) {
            background-image: url("../../../public/assets/images/ImgbgMajorM.png");
            background-repeat: no-repeat;
            background-size: 100% auto;
            margin-top: 10px;
        }
    }
    &.not-found {
        @media only screen and (max-width: 992px) {
            padding-bottom: 30px;
        }
        .mc-app-container {
            @media only screen and (max-width: 992px) {
                padding: 0 14px;
            }
        }
    }
    &.faqs,
    &.terms {
        .informationTextTitle {
            @media only screen and (min-width: 992px) {
                padding-top: 15px;
            }
        }
    }
    &.alliances {
        .containerAlliances {
            margin-bottom: 60px;
            @media only screen and (max-width: 992px) {
                background-image: url("../../../public/assets/images/ImgbgMajorM.png");
                background-repeat: no-repeat;
                background-size: cover;
                padding-bottom: 200px;
            }
            & > .mc-app-container {
                padding-left: 0px;
            }
        }
        & > .mc-app-container {
            @media only screen and (max-width: 992px) {
                margin-top: -206px;
            }
        }
    }
    &.redemptions {
        .mc-goal-counter  {
            @media only screen and (max-width: 992px) {
                margin-bottom: 30px;
            }
            & > div {
                > div {
                    display: none !important;
                }
            }
        }
        .mc-banner-container {
            @media only screen and (max-width: 992px) {
                margin-top: 40px;
            }
        }
    }
}

.banner-major-award {
    position: relative;

    a {
        position: absolute;
        right: 12%;
        height: 10%;
        bottom: 24%;
        width: 30%;
        z-index: 2;

        @media only screen and (max-width: 992px) {
            right: 18%;
            height: 6%;
            bottom: 52%;
            width: 64%;
        }
    }

    img {
        width: 100%;
        height: auto;
    }
}