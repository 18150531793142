.mc-header-container {
  position: relative;

  @media only screen and (min-width: 992px) {
    z-index: 3;
  }

  &__menu {
    position: relative;
    height: 70px;
    // background-color: rgb(204, 204, 204);
    background: rgb(3, 133, 197);
    background: linear-gradient(90deg, rgba(3, 133, 197, 1) 0%, rgba(0, 60, 103, 1) 100%);

    @media only screen and (min-width: 992px) {
      background-color: rgb(179, 179, 179);
      z-index: 2;
      height: 60px;

      & > .mc-app-container {
        max-width: 90%;
      }
    }
  }

  &__submenu {
    position: relative;
    // height: 60px;
    background-color: rgb(141, 141, 141);

    @media only screen and (min-width: 992px) {
      z-index: 1;
    }

    &.not-login {
      display: none;
    }
  }

  &__logo {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    top: 0px;
    width: 50%;
    z-index: 1;
    text-align: center;
    height: 100%;
    max-width: 220px;

    @media only screen and (min-width: 992px) {
      right: auto;
      width: 40px;
    }

    // img {
    //   width: 100%;
    //   height: auto;
    // }
  }

  &__mobile--close {
    position: absolute;
    right: 20px;
    top: 0px;
    width: 50%;
    z-index: 1;
    text-align: right;
    height: 100%;
    max-width: 80px;

    .icon-close_session {
      width: 26px;
      height: 26px;
      position: relative;
      display: inline-block;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    @media only screen and (min-width: 992px) {
      background-color: rgb(255, 255, 255);
      text-align: center;
      height: 42px;
      bottom: 0px;
      margin: auto;
      width: 70px;
      display: none !important;
    }
  }

  &__mobile--submenu {
    position: absolute;
    right: 20px;
    top: 0px;
    width: 50%;
    z-index: 1;
    text-align: right;
    height: 100%;
    max-width: 80px;
    cursor: pointer;

    .icon-menu {
      position: relative;
      top: 2px;
      transform: scale(0.9);
    }
  }

  &__nav {
    position: fixed;
    right: 0px;
    left: 0px;
    text-align: right;
    bottom: 0px;
    z-index: 10;
    background-color: gray;
    height: 70px;

    @media only screen and (min-width: 992px) {
      position: absolute;
      right: 0px;
      top: 0px;
      width: auto;
      left: 32%;
      background-color: transparent;
      height: 60px;
      .custom-hr, .sesionCloseBtn {
        display: none;
      }
    }

    ul {
      padding: 0px;
      margin: 0px;
      height: 100%;
      width: 100%;
      position: relative;
      z-index: 2;

      li {
        float: left;
        width: 25%;
        height: 100%;
        list-style: none;
        text-align: center;

        @media only screen and (min-width: 992px) {
          width: auto;
          float: none;
          display: inline-block;
          vertical-align: middle;
          padding: 0 12px;
          margin: 0 12px;
        }

        a,
        .mc-header-container__nav--button {
          display: block;
          height: 100%;
          width: 100%;
          cursor: pointer;
          color: #fff;
          text-decoration: none;

          @media only screen and (max-width: 992px) {
            font-size: 12px;
          }

          &:hover {
            opacity: 0.7;
          }

          .mc-icon {
            @media only screen and (min-width: 992px) {
              display: inline-block;
              vertical-align: middle;
              margin-right: 8px;
            }
          }
        }

        &.mc-header-container__nav--item-active {
          background-color: #fff;
          @media only screen and (min-width: 992px) {
            background-color: transparent;
          }
          @media only screen and (max-width: 992px) {
            * {
              color: $colorText;
            }
  
            .mc-icon {
              svg {
                path {
                  stroke: $colorText;
                }
              }
            }
          }

        }
      }
    }

    &--extra {
      position: fixed;
      right: -20px;
      bottom: 70px;
      background-color: #fff;
      z-index: 1;
      box-sizing: border-box;
      padding: 12px 20px;
      text-align: right;
      min-width: 170px;
      outline: 1px solid rgb(230, 230, 230);
      visibility: hidden;
      opacity: 0;

      @media only screen and (min-width: 992px) {
        position: absolute;
        top: 70px;
        bottom: auto;
        right: 56px !important;
        outline: transparent;
        -webkit-box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        padding-left: 30px;
        width: 293px;
      }

      a,
      .mc-header-container__nav--close {
        display: block;
        padding: 10px 0px;
        text-decoration: none;
        cursor: pointer;

        @media only screen and (min-width: 992px) {
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          color: #0056CB;
        }

        &:hover {
          opacity: 0.7;
        }
      }

      .mc-header-container__nav--close {
        border-top: 1px solid rgb(207, 207, 207);
        margin-top: 6px;
        padding-top: 13px;

        @media only screen and (min-width: 992px) {
          color: #AA0D24;
        }
      }

      &.opened {
        visibility: visible;
        opacity: 1;
        right: 0px;
      }

    }
  }

  &.mc-header-container-type-2 {

    .mc-header-container__menu {
      text-align: center;

      @media only screen and (min-width: 992px) {
        height: 60px;
      }

      .mc-header-container__logo {
        left: 0px;
        right: 0px;
        margin: auto;

        @media only screen and (min-width: 992px) {
          // height: 50%;
          width: 100%;
          max-width: 1350px;
          text-align: left;
        }
      }

      .mc-header-container__nav {
        @media only screen and (min-width: 992px) {
          left: 0px;
          right: 0px;
          text-align: center;
          top: auto;
          bottom: 0px;
          height: 50%;

          .mc-header-link {
            line-height: 52px;
          }

          .mc-header-container__nav--extra {
            right: -32% !important;
            left: 0;
            width: 300px;
            margin: auto;
            top: 55px;
          }
        }

        ul {
          li {
            .mc-icon {
              display: inline-block;
              vertical-align: middle;
              margin-right: 6px;
            }

            .mc-app-centry {
              &>span {
                display: inline-block;
                padding: 5px 7px;
              }
            }

            &.mc-header-container__nav--item-active {
              background-color: transparent;

              .mc-app-centry {
                &>span {
                  background-color: #000;
                  color: #fff;

                  .mc-icon {
                    svg {
                      path {
                        fill: #fff !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.mc-header-container-type-3 {

    .mc-header-container__menu {
      text-align: left;

      @media only screen and (min-width: 992px) {
        background-color: transparent !important;
      }

      .mc-header-container__logo {
        left: 20px;
        right: auto;
        margin: auto;
      }

      .mc-header-container__nav {
        @media only screen and (min-width: 992px) {
          background-color: rgb(141, 141, 141);
          right: 0px;
          left: auto;
          width: auto;
          -webkit-box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.3);
          box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.3);

          .mc-header-container__nav--extra {
            right: 0px !important;
          }
        }

        ul {
          li {

            a,
            .mc-header-container__nav--button {
              &:hover {
                opacity: 1;
              }
            }

            .mc-header-container__nav--button {
              .mc-app-centry {
                &>span {
                  .mc-icon {
                    display: none !important;

                    @media only screen and (min-width: 992px) {
                      display: inline-block !important;
                      position: relative;
                      opacity: 1;
                      visibility: visible;
                    }
                  }
                }
              }
            }

            .mc-icon {
              position: absolute;
              left: 0px;
              right: 0px;
              top: -26px;
              background-color: #fff;
              border-radius: 100px;
              width: 40px;
              height: 40px;
              text-align: center;
              margin: auto;
              -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
              -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
              box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
              opacity: 0;

              @media only screen and (min-width: 992px) {
                position: relative;
                top: auto;
                background-color: transparent;
                -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0);
                -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0);
                box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0);
                opacity: 1;
                width: 35px;
                height: 35px;
                top: 4px;
              }

              &>span {
                position: relative;
                top: 8px;

                @media only screen and (min-width: 992px) {
                  top: auto;
                }
              }
            }

            .mc-app-centry {
              &>span {
                display: inline-block;
                padding: 5px 7px;

                @media only screen and (min-width: 992px) {
                  top: -4px;
                  position: relative;
                }
              }
            }

            &.mc-header-container__nav--item-active {
              background-color: transparent;

              .mc-app-centry {
                &>span {
                  color: #fff;

                  .mc-icon {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.mc-header-container-type-4 {

    @media only screen and (min-width: 992px) {
      z-index: 9;
    }

    @media only screen and (min-width: 992px) {
      .mc-header-container__menu {
        .mc-header-container__logo {
          left: 0px;
          right: 0px;
          margin: auto;
        }

        .mc-header-container__mobile--submenu {
          .mc-icon {
            svg {
              transform: scale(0.85);
            }
          }
        }

        .mc-header-container__nav {
          ul {
            @media only screen and (min-width: 992px) {
              padding-right: 10px;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 992px) {
      .custom-hr {
        margin-right: 16px;
      }
    }

    .mc-header-container__nav {
      position: fixed;
      left: 0px;
      top: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 999;
      height: auto;
      width: auto;
      background-color: transparent;
      visibility: hidden;
      -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
      transition: all .3s cubic-bezier(.77, 0, .175, 1);

      .mc-header-container__mobile--overlay {
        position: absolute;
        left: 0px;
        top: 0px;
        bottom: 0px;
        right: 0px;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.6);
        -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        transition: all .3s cubic-bezier(.77, 0, .175, 1);
        visibility: hidden;
        opacity: 0;
      }

      .mc-header-container__nav--close {
        position: absolute;
        right: -15px;
        top: 12px;
        color: $colorText;
        display: block;
        text-align: right;
        z-index: 3;
        padding: 16px 26px;
        box-sizing: border-box;
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        transition: all .3s cubic-bezier(.77, 0, .175, 1);

        @media only screen and (max-width: 992px) {
          padding-right: 16px;
        }
      }

      ul {
        background-color: #fff;
        right: -280px;
        top: 0px;
        width: 280px;
        height: 100%;
        position: absolute;
        box-sizing: border-box;
        padding-top: 160px;
        -webkit-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -moz-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        -o-transition: all .3s cubic-bezier(.77, 0, .175, 1);
        transition: all .3s cubic-bezier(.77, 0, .175, 1);
        visibility: hidden;
        opacity: 0;

        li {
          float: none;
          display: block;
          width: 100%;
          box-sizing: border-box;
          height: 24px;
          margin-bottom: 20px;
          text-align: right;
          padding: 0 30px;

          @media only screen and (max-width: 992px) {
            padding: 0 18px;
          }

          a {
            height: 60px;

            .mc-app-centry {
              &>span {
                color: #0056CB;
                text-align: left;
                font-family: 'Gelion Regular';
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                text-align: right;


                .mc-icon {
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 5px;

                  svg {
                    path {
                      stroke: $colorText;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .mc-header-container__nav--extra {
        display: none !important;
      }
    }

    &.show-menu {
      .mc-header-container__nav {
        visibility: visible !important;

        .mc-header-container__mobile--overlay,
        .mc-header-container__nav--close {
          visibility: visible !important;
          opacity: 1 !important;
          right: 0px !important;
        }

        ul {
          right: 0px;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.mc-header-link {
  line-height: 65px;
  font-size: 15px;
  font-weight: 600;
}

// Desktop Occidente 2
.header-container__menu {
  height: 60px;
}


.sesionCloseBtn {
  border: none;
  background-color: white;
  font-family: 'Gelion Regular';
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  color: #AA0D24;

  @media only screen and (max-width: 992px) {
    padding-right: 16px;
  }

  img {
    position: relative;
    top: 3px;
  }
}

.mc-header-container__nav--close {
  margin-top: 80px;
}

.custom-hr {
  border: 0;
  width: 261px;
  height: 1px; 
  background: #DFE5F9; 
}

.sesionCloseBtn{
  margin-top: 15px;
  cursor: pointer;
}


// height: 0px;
// gap: 0px;
// border: 1px 0px 0px 0px;
// opacity: 0px;
// angle: 180 deg;

// .containerMenuNav{
//   width: 198px;
//   height: 288px !important;
// }