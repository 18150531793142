.mc-goal-counter {
	position: relative;
	background-color: #F3F6FE;
	border: 1px solid #ADBAE6;
	box-sizing: border-box;
	padding: 14px;
	text-align: center;
	border-radius: 8px;
  width: 752px;
  margin: 20px auto;

  @media only screen and (max-width: 992px) {
    width: auto;
  }
	
	div {
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: 24px;
    vertical-align: middle;
    color: #022047;

    div {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #022047;
    }

    strong {
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px;
      font-family: 'Gelion Bold';
      vertical-align: middle;
      margin: 0 6px;
      position: relative;
      top: -2px;
      color: #0056CB;
    }
	}
}