.mc-app-container {
  position: relative;
  display: block;
  width: 1100px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 20px;

  @media only screen and (min-width: 992px) {
    max-width: 100%;
    margin: 0 auto;
    z-index: 0;
  }
}